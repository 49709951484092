import React from 'react'
import Layout from '../Layout/Layout'
import '../BlogDetails/Blogdetail.css'
function Blogdetail() {
  return (
   <Layout>
<div id="main-content" className="blog-page">
  <div className="container">
    <div className="row clearfix">
      <div className="col-lg-8 col-md-12 left-box">
        <div className="post-card single_post">
          <div className="body">
            <div className="img-post">
              <img className="d-block img-fluid" src="https://www.bootdey.com/image/800x280/FFB6C1/000000" alt="First slide" />
            </div>
            <h3><a href="blog-details.html">All photographs are accurate</a></h3>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal</p>
           <p> Title: Exploring the Beauty of Nature

Image Description: A stunning view of a lush green forest with a clear blue sky.

Body Content: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut eros nec lectus viverra iaculis. Curabitur fermentum justo eu purus vehicula, ac lacinia urna facilisis. Vivamus scelerisque arcu at nibh pharetra, nec sollicitudin ligula vehicula. Nulla id nunc a mauris condimentum tincidunt. Aenean et quam ac justo ullamcorper fringilla vel ac odio. Cras vestibulum venenatis enim, sed tempus orci fermentum et. Suspendisse potenti.

Meta Information:

Date: January 20, 2025
Category: Nature
Author: John Doe
</p> 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-12 right-box">
       
        <div className="post-card">
          <div className="header">
            <h2 style={{color:"black"}}>Categories Clouds</h2>
          </div>
          <div className="body widget">
            <ul className="list-unstyled categories-clouds m-b-0">
              <li><a href="javascript:void(0);">eCommerce</a></li>
              <li><a href="javascript:void(0);">Microsoft Technologies</a></li>
              <li><a href="javascript:void(0);">Creative UX</a></li>
              <li><a href="javascript:void(0);">Wordpress</a></li>
              <li><a href="javascript:void(0);">Angular JS</a></li>
              <li><a href="javascript:void(0);">Enterprise Mobility</a></li>
              <li><a href="javascript:void(0);">Website Design</a></li>
              <li><a href="javascript:void(0);">HTML5</a></li>
              <li><a href="javascript:void(0);">Infographics</a></li>
              <li><a href="javascript:void(0);">Wordpress Development</a></li>
            </ul>
          </div>
        </div>
        <div className="post-card">
          <div className="header">
            <h2 style={{color:"black"}}>Popular Posts</h2>                        
          </div>
          <div className="body widget popular-post">
            <div className="row">
              <div className="col-lg-12">
                <div className="single_post">
                  <p className="m-b-0">Apple Introduces Search Ads Basic</p>
                  <span>jun 22, 2018</span>
                  <div className="img-post">
                    <img src="https://www.bootdey.com/image/280x280/FFB6C1/000000" alt="Awesome Image" />                                        
                  </div>                                            
                </div>
                <div className="single_post">
                  <p className="m-b-0">new rules, more cars, more races</p>
                  <span>jun 8, 2018</span>
                  <div className="img-post">
                    <img src="https://www.bootdey.com/image/280x280/FFB6C1/000000" alt="Awesome Image" />                                            
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="post-card">
          <div className="header">
            <h2>Instagram Post</h2>
          </div>
          <div className="body widget">
            <ul className="list-unstyled instagram-plugin m-b-0">
              <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
              <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
              <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
              <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
              <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
              <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
              <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
              <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
              <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
            </ul>
          </div>
        </div> */}
        {/* <div className="post-card">
          <div className="header">
            <h2>Email Newsletter <small>Get our products/news earlier than others, let’s get in touch.</small></h2>
          </div>
          <div className="body widget newsletter">                        
            <div className="input-group">
              <input type="text" className="form-control" placeholder="Enter Email" />
              <div className="input-group-append">
                <span className="input-group-text"><i className="icon-paper-plane" /></span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  </div>
</div> 


   </Layout>
  )
}

export default Blogdetail