import React from 'react'
import Layout from '../Layout/Layout'
import '../Web3-Game-Development/Web3GameDevelopment.css'

const Web3GameDevelopment = () => {
  return (
    <Layout>  
 <section className="bannerSC">
            <div className='video_fadeoverlay'></div>
            <video autoplay="true" muted="true" loop="true">
  <source src="./Assets/web30/BlockCoaster_Web.mp4" type="video/mp4" />
  <source type="#" src="video.mp4"></source>

</video>
                {/* <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="banner-caption2">
                                <h1> <span className=''>Metaverse</span> Development Services</h1>
                                <p>Bringing Virtual Worlds to Life - Explore our Metaverse development services and step into a new dimension of immersive experiences with Blockcoaster.</p>


                            </div>
                        </div>
                        
                        <div className="col-12 col-md-6 text-center">
                      <div className="banner-img">
                        <img src="./Assets/banner-sideimg.png" className='img-fluid' alt="" />
                        
                      </div>
                    </div>
                    </div>
                </div> */}
            </section>

    </Layout>
  )
}

export default Web3GameDevelopment