import React from 'react'
import Layout from '../Layout/Layout'
import '../Meta-VirtualPlatform/MetaVirtualPlatform.css'
const MetaVirtualPlatform = () => {
  return (
    <Layout>
         
    <section className="bannerMV">  

<div className="container">
<div className="row ">
<div className="col-12 col-md-6">
 <div className="banner-caption2">
   <h1> <span className='block-clr'>Metaverse Virtual Event 
   </span>Platform Development Services
   </h1>
   <p>Create immersive, impactful, and versatile virtual events that captivate audiences from across the globe. Our cutting-edge metaverse event platform provides a fully customizable 3D experience designed to elevate your event management and attendee engagement, offering dynamic features tailored to modern event coordination needs.


   </p>


 </div>
</div>

<div className="col-12 col-md-6 text-center">
 <div>
   <img src="./Assets/metaverse/meta-virtualevent/virtualevent.png" className='img-fluid' alt="" />

 </div>
</div>
</div>
</div>
</section>

<section className='bgrightMV'>
    <div className='container-fluid'>
     <div className='row'>
          <div className='col-12'>
          <div class="heading-box">
               <h3>Innovative Solutions for Engaging Virtual Events

               </h3>
               {/* <div class="line2"></div> */}
             </div>    
          </div>
     </div>
     <div className='row justify-content-center'>
          <div className='col-12 col-md-5'>
          <div class="card type-card">
               
               <div class="row">
               <div class="col-3">
               <div class="type-icon">
                     <img src="./Assets/metaverse/meta-virtualevent/1.png" alt="" />
               </div>
               </div>
               <div class="col-9">
               <div class="type-caption">
                     <h3>Customizable 3D Environments
                     </h3>
                    <p> Tailor virtual spaces to reflect your brand, theme, or specific event needs, allowing for a unique attendee experience.

                    </p>
               </div>
               </div>
               </div>
             </div>    
          </div>

          {/* 2 */}
          <div className='col-12 col-md-5'>
          <div class="card type-card">
               
               <div class="row">
               <div class="col-3">
               <div class="type-icon">
                     <img src="./Assets/metaverse/meta-virtualevent/2.png" alt="" />
               </div>
               </div>
               <div class="col-9">
               <div class="type-caption">
                     <h3>Seamless Event Management
                     </h3>
                    <p>Streamline event organization with tools for planning, coordinating, and executing events within a virtual space.
                    </p>
               </div>
               </div>
               </div>
             </div>    
          </div>
       

           {/* 3 */}
           <div className='col-12 col-md-5'>
          <div class="card type-card">
               
               <div class="row">
               <div class="col-3">
               <div class="type-icon">
                     <img src="./Assets/metaverse/meta-virtualevent/3.png" alt="" />
               </div>
               </div>
               <div class="col-9">
               <div class="type-caption">
                     <h3>Real-Time Networking Capabilities
                     </h3>
                    <p> Enable attendees to interact through networking lounges, breakout rooms, and spatial audio to simulate in-person connections.

                    </p>
               </div>
               </div>
               </div>
             </div>    
          </div>

          {/* 4 */}
          <div className='col-12 col-md-5'>
          <div class="card type-card">
               
               <div class="row">
               <div class="col-3">
               <div class="type-icon">
                     <img src="./Assets/metaverse/meta-virtualevent/4.png" alt="" />
               </div>
               </div>
               <div class="col-9">
               <div class="type-caption">
                     <h3>Interactive Avatars and Personalization
                     </h3>
                    <p>Create a more engaging environment with avatars that attendees can customize, enhancing their sense of presence.

                    </p>
               </div>
               </div>
               </div>
             </div>    
          </div>

          {/* 5 */}
          <div className='col-12 col-md-5'>
          <div class="card type-card">
               
               <div class="row">
               <div class="col-3">
               <div class="type-icon">
                     <img src="./Assets/metaverse/meta-virtualevent/5.png" alt="" />
               </div>
               </div>
               <div class="col-9">
               <div class="type-caption">
                     <h3>Advanced Analytics

                     </h3>
                    <p>Access data and insights on attendee engagement, session popularity, and real-time participation to optimize event outcomes.
                    </p>
               </div>
               </div>
               </div>
             </div>    
          </div>

        

          <div className='col-12 col-md-5'>
          <div class="card type-card">
               
               <div class="row">
               <div class="col-3">
               <div class="type-icon">
                     <img src="./Assets/metaverse/meta-virtualevent/6.png" alt="" />
               </div>
               </div>
               <div class="col-9">
               <div class="type-caption">
                     <h3>Sponsorship and Monetization Options

                     </h3>
                    <p> Offer sponsorship opportunities, ad placements, and digital goods to increase revenue streams within the virtual platform.

                    </p>
               </div>
               </div>
               </div>
             </div>    
          </div>

          <div className='col-12 col-md-5'>
          <div class="card type-card">
               
               <div class="row">
               <div class="col-3">
               <div class="type-icon">
                     <img src="./Assets/metaverse/meta-virtualevent/7.png" alt="" />
               </div>
               </div>
               <div class="col-9">
               <div class="type-caption">
                     <h3>Live Streaming and On-Demand Content

                     </h3>
                    <p>Allow attendees to watch sessions live or access recordings post-event, extending engagement beyond the event duration.

                    </p>
               </div>
               </div>
               </div>
             </div>    
          </div>
     </div>
    </div>

   </section>

   
{/* 2 */}
<section className='bgleftMV'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>Versatile Event Experiences Inside a 3D Virtual Space

                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row align-items-center'>
                        <div className='col-12 col-md-6 pt-3'>
                            <div class="heading-box-2">
                                <p>Within our 3D metaverse event platform, the range of event possibilities is vast. Organizations can host product launches, training seminars, exhibitions, global summits, virtual expos, concerts, and more. From interactive trade shows to conferences with personalized breakout sessions and workshops, our platform offers features that make any event engaging and memorable. Attendees can participate in real-time discussions, visit virtual exhibition booths, or even connect through spatial audio networking, providing a sense of presence and immersion that matches in-person gatherings.

                                </p>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 text-center'>
                            <div class="why-img">
                                <img src="./Assets/metaverse/meta-virtualevent/1011.png" className='img-fluid' alt="not" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>  
   

    
    {/* 4 */}

    <section className='bgrightMV'>
     <div className='container'>
     <div className='row'>
          <div className='col-12'>
          <div class="heading-box">
               <h3> Advanced Features for Unmatched Engagement

               </h3>

               <p>Our metaverse virtual event platform offers a host of innovative features designed for today’s digital event landscape
               </p>
               {/* <div class="line2"></div> */}
             </div>   
          </div>
     </div>
     <div className='row justify-content-center'>
          <div className='col-12 col-md-4'>
                <div className='cardS card-blockchainS'>
                       <div className='block-iconD'>
                          <img src="./Assets/metaverse/meta-virtualevent/8.png" alt="" />
                       </div>
                     <h3>Customizable 3D Environments
                     </h3>
                     <p> Fully tailored spaces that reflect your brand and event themes.

                     </p>
                        


                </div>
          </div>

          {/* 2 */}
          <div className='col-12 col-md-4'>
                <div className='cardS card-blockchainS'>
                       <div className='block-iconD'>
                          <img src="./Assets/metaverse/meta-virtualevent/9.png" alt="" />
                       </div>
                     <h3>Networking Lounges and Breakout Rooms
                     </h3>
                     <p>Allow attendees to interact seamlessly with options for private or group conversations.
                     </p>
                </div>
          </div>

          {/* 3 */}
          <div className='col-12 col-md-4'>
                <div className='cardS card-blockchainS'>
                       <div className='block-iconD'>
                          <img src="./Assets/metaverse/meta-virtualevent/10.png" alt="" />
                       </div>
                     <h3>Interactive Avatars
                     </h3>
                     <p>Encourage a dynamic and engaging experience with customizable avatars for each attendee.
                     </p>
                        


                </div>
          </div>
          {/* 4 */}
          <div className='col-12 col-md-4'>
                <div className='cardS card-blockchainS'>
                       <div className='block-iconD'>
                          <img src="./Assets/metaverse/meta-virtualevent/11.png" alt="" />
                       </div>
                     <h3>Real-time Analytics
                     </h3>
                     <p>Track engagement metrics, attendee numbers, and interactions to understand event success.

                     </p>
                </div>
          </div>

          <div className='col-12 col-md-4'>
                <div className='cardS card-blockchainS'>
                       <div className='block-iconD'>
                          <img src="./Assets/metaverse/meta-virtualevent/12.png" alt="" />
                       </div>
                     <h3>Gamified Experiences
                     </h3>
                     <p>Keep participants engaged through interactive elements such as quizzes, scavenger hunts, and mini-games within the metaverse space.


                     </p>
                </div>
          </div>
     </div>
     </div>
 </section>
 {/* 5 */}

<section className='bgleftMV'>
 <div className="wrapper">
  <h1>Key Benefits of a Metaverse Virtual Event Platform

  </h1>
  <p style={{marginLeft:"20%"}}>The metaverse virtual event platform brings a variety of benefits for businesses and event planners  </p>
  <div class="cols">
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
                          
							<span>Global Accessibility
                            </span>
                         <img src="./Assets/metaverse/meta-virtualevent/13.png" alt="" width="150px" />

						</div>
					</div>
					<div class="back">
						<div class="inner">
						  <span> Reach participants worldwide without the limitations of a physical location.


                          </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Cost Savings
                            </span>
                            <img src="./Assets/metaverse/meta-virtualevent/14.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span> Reduce expenses related to physical venues, logistics, and travel by transitioning events into the metaverse.


                            </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Enhanced Engagement
                            </span>
                            <img src="./Assets/metaverse/meta-virtualevent/15.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span> Immersive 3D spaces, interactive features, and avatars make events highly engaging and memorable.

                            </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Scalability and Flexibility
                            </span>
                            <img src="./Assets/metaverse/meta-virtualevent/166.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span>Host events of all sizes, from intimate gatherings to large-scale conferences, within a single adaptable platform.


                            </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Sustainability
                            </span>
                            <img src="./Assets/metaverse/meta-virtualevent/177.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span>Minimize carbon footprint by reducing the need for travel and physical event infrastructure.


                            </span>
						</div>
					</div>
				</div>
			</div>
		
		</div>
 </div>
</section>



 <section className='bgrightMV'>
     <div className='container-fluid'>
     <div className='row'>
          <div className='col-12'>
          <div class="heading-box">
               <h3>Revenue Streams Through a Metaverse Event Platform

               </h3>
               <p>
               Maximize your event’s earning potential by taking advantage of multiple monetization options on a metaverse platform
               </p>
               {/* <div class="line2"></div> */}
             </div>   
          </div>
     </div>
     <div className='row justify-content-center'>
          <div className='col-12 col-md-5'>
                <div className='cardI Industry-blockchainI'>
                       <div className='row'>
                         <div className='col-3 text-center'>
                                <div className='indus-img'>
                                   <img src="./Assets/metaverse/meta-virtualevent/188.png" className='img-fluid' alt="" />
                                </div>
                         </div>
                         <div className='col-9'>
                              <div className='indus-caption '>
                              <h3>Ticket Sales and Paid Access
                     </h3>
                     <p>Sell tickets with tiered access to offer different levels of engagement, from general admission to VIP experiences.

                     </p>
                        
                              </div>
                         </div>
                       </div>
              


                </div>
          </div>

          {/* 2 */}
       
          {/* 3 */}
    

    
          {/* 6 */}
          <div className='col-12 col-md-5'>
                <div className='cardI Industry-blockchainI'>
                       <div className='row'>
                         <div className='col-3 text-center'>
                                <div className='indus-img'>
                                   <img src="./Assets/metaverse/meta-virtualevent/19.png" className='img-fluid' alt="" />
                                </div>
                         </div>
                         <div className='col-9'>
                              <div className='indus-caption '>
                              <h3>Sponsored Booths and Advertisements
                     </h3>
                     <p> Offer virtual booths for sponsors, branded spaces, and interactive advertisements within the event.

                     </p>
                        
                              </div>
                         </div>
                       </div>
              


                </div>
          </div>

          {/* 7 */}
          <div className='col-12 col-md-5'>
                <div className='cardI Industry-blockchainI'>
                       <div className='row'>
                         <div className='col-3 text-center'>
                                <div className='indus-img'>
                                   <img src="./Assets/metaverse/meta-virtualevent/20.png" className='img-fluid' alt="" />
                                </div>
                         </div>
                         <div className='col-9'>
                              <div className='indus-caption '>
                              <h3>Virtual Goods and Merchandise
                     </h3>
                     <p>Enable attendees to purchase branded items or digital assets, enhancing their virtual experience.

                     </p>
                        
                              </div>
                         </div>
                       </div>
              


                </div>
          </div>

          {/* 8 */}
          <div className='col-12 col-md-5'>
                <div className='cardI Industry-blockchainI'>
                       <div className='row'>
                         <div className='col-3 text-center'>
                                <div className='indus-img'>
                                   <img src="./Assets/metaverse/meta-virtualevent/21.png" className='img-fluid' alt="" />
                                </div>
                         </div>
                         <div className='col-9'>
                              <div className='indus-caption '>
                              <h3>On-Demand Content
                     </h3>
                     <p>Provide recorded sessions or exclusive content for post-event monetization.


                     </p>
                        
                              </div>
                         </div>
                       </div>
              


                </div>
          </div>
           {/* 9 */}
           <div className='col-12 col-md-5'>
                <div className='cardI Industry-blockchainI'>
                       <div className='row'>
                         <div className='col-3 text-center'>
                                <div className='indus-img'>
                                   <img src="./Assets/staking/13.png" className='img-fluid' alt="" />
                                </div>
                         </div>
                         <div className='col-9'>
                              <div className='indus-caption '>
                              <h3>Premium Networking Opportunities
                     </h3>
                     <p> Charge for access to exclusive networking lounges or breakout sessions to connect attendees with industry leaders.
                     .
                     </p>
                        
                              </div>
                         </div>
                       </div>
              


                </div>
          </div>

          {/* 10 */}
       
     </div>
     </div>
 </section>




   <section className="bannerMV">

<div className="container">
<div className="row ">
<div className="col-12 col-md-6">
 <div className="banner-caption2">
   <h1> <span className='block-clr'>Why Choose Our 

   </span>Metaverse Development Services?
   </h1>
   <p>As experts in metaverse development, we understand the nuances of creating captivating virtual environments that deliver real-world results. Our platform combines flexibility, advanced features, and an intuitive user experience, providing a high-quality digital space for seamless event arrangement and execution. By choosing our services, you partner with a team committed to innovation and excellence, offering ongoing support to ensure your virtual events are both impactful and memorable.


   </p>


 </div>
</div>

<div className="col-12 col-md-6 text-center">
 <div>
   <img src="./Assets/metaverse/meta-virtualevent/18.png" width="300px" style={{marginTop:"20%"}} className='img-fluid' alt="" />

 </div>
</div>
</div>
</div>
</section>

</Layout>
  )
}

export default MetaVirtualPlatform
