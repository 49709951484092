import React from 'react'
import Layout from '../Layout/Layout'
import '../Blog/blog.css'
import { Link } from 'react-router-dom'

const blog = () => {
  
    return (
        <Layout>
<section className="content-section bgdark1">
<div className="col-12">
<div className="heading-box-sec text-center py-4">
      <h3> Recent Updates
      </h3>
    </div>
 </div>
  <div className="container">
    <div className="owl-carousel owl-theme">
      <div className="item">
        <div className="content-wrapper">
          
          <div className="image-container1">
            <img src="Assets/TailoredforYou.jpg" alt="Placeholder" />
          </div>
          <div className="text-container">
            <h2>Heading for the Section</h2>
            <p>
              This is a sample text that describes the content on the right side.
              You can replace this text with any relevant content that you want
              to showcase. This layout uses flexbox to arrange the image and text
              side by side, and it is responsive for different screen sizes.
            </p>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="content-wrapper">
          <div className="image-container1">
            <img src="Assets/TailoredforYou.jpg" alt="Placeholder" />
          </div>
          <div className="text-container">
            <h2>Another Heading</h2>
            <p>
              This is some more sample text to demonstrate how you can have
              multiple slides with different content in the carousel.
            </p>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="content-wrapper">
          <div className="image-container1">
            <img src="Assets/TailoredforYou.jpg" alt="Placeholder" />
          </div>
          <div className="text-container">
            <h2>Another Heading</h2>
            <p>
              This is some more sample text to demonstrate how you can have
              multiple slides with different content in the carousel.
            </p>
          </div>
        </div>
      </div>
      {/* Add more items as needed */}
    </div>
  </div>
</section>


  <section className="bgdark1">
  <div className="col-12">
  <div className="heading-box-sec text-center py-4">
  <h3>Discover in-depth insights, expert , tips ,and the latest trends in blockchain technology and web3 development.</h3>
</div>
</div>
    <div className='container'>
    <div className="blog-container">
            <div className="blog-card">
                <img src="Assets/3d-design/3D game.png" alt="Blog Image" />
                <div className="blog-card-content">
                    <div className="blog-date">January 13, 2025</div>
                    <div className="blog-author">By John Doe</div>
                    <div className="blog-title">The Future of Healthcare</div>
                    <div className="blog-excerpt">
                        The healthcare industry is evolving rapidly with the advent of new technologies. This article explores how AI, telemedicine, and digital health are shaping the future...
                    </div>
                    <Link to="/BlogDetails" className="blog-read-more">Read more</Link>
                </div>
            </div>
            <div className="blog-card">
                <img src="Assets/3d-design/3D game.png" alt="Blog Image" />
                <div className="blog-card-content">
                    <div className="blog-date">January 13, 2025</div>
                    <div className="blog-author">By John Doe</div>
                    <div className="blog-title">The Future of Healthcare</div>
                    <div className="blog-excerpt">
                        The healthcare industry is evolving rapidly with the advent of new technologies. This article explores how AI, telemedicine, and digital health are shaping the future...
                    </div>
                    <Link to="/BlogDetails" className="blog-read-more">Read more</Link>
                </div>
            </div>

            <div className="blog-card">
                <img src="Assets/3d-design/3D game.png" alt="Blog Image" />
                <div className="blog-card-content">
                    <div className="blog-date">January 13, 2025</div>
                    <div className="blog-author">By John Doe</div>
                    <div className="blog-title">The Future of Healthcare</div>
                    <div className="blog-excerpt">
                        The healthcare industry is evolving rapidly with the advent of new technologies. This article explores how AI, telemedicine, and digital health are shaping the future...
                    </div>
                    <Link to="/BlogDetails" className="blog-read-more">Read more</Link>
                </div>
            </div>

            <div className="blog-card">
                <img src="Assets/3d-design/3D game.png" alt="Blog Image" />
                <div className="blog-card-content">
                    <div className="blog-date">January 13, 2025</div>
                    <div className="blog-author">By John Doe</div>
                    <div className="blog-title">The Future of Healthcare</div>
                    <div className="blog-excerpt">
                        The healthcare industry is evolving rapidly with the advent of new technologies. This article explores how AI, telemedicine, and digital health are shaping the future...
                    </div>
                    <Link to="/BlogDetails" className="blog-read-more">Read more</Link>
                </div>
            </div>

            <div className="blog-card">
                <img src="Assets/3d-design/3D game.png" alt="Blog Image" />
                <div className="blog-card-content">
                    <div className="blog-date">January 13, 2025</div>
                    <div className="blog-author">By John Doe</div>
                    <div className="blog-title">The Future of Healthcare</div>
                    <div className="blog-excerpt">
                        The healthcare industry is evolving rapidly with the advent of new technologies. This article explores how AI, telemedicine, and digital health are shaping the future...
                    </div>
                    <Link to="/BlogDetails" className="blog-read-more">Read more</Link>
                </div>
            </div>
            <div className="blog-card">
                <img src="Assets/3d-design/3D game.png" alt="Blog Image" />
                <div className="blog-card-content">
                    <div className="blog-date">January 13, 2025</div>
                    <div className="blog-author">By John Doe</div>
                    <div className="blog-title">The Future of Healthcare</div>
                    <div className="blog-excerpt">
                        The healthcare industry is evolving rapidly with the advent of new technologies. This article explores how AI, telemedicine, and digital health are shaping the future...
                    </div>
                    <Link to="/BlogDetails" className="blog-read-more">Read more</Link>
                </div>
            </div>
        </div>
</div>
</section>






{/* 
<div className="container">
  <h4>Latest Updates</h4>
  <div className="row">
    <div className="col-md-12">
      <div id="clients-slider" className="owl-carousel">
        <div className="client">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12 text-center">
              <img src="https://www.blockchainappfactory.com/blog/wp-content/uploads/2025/01/On-chain-Crypto-Coin-Development.webp" />
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="post_category">
                <a className="btn">Cryptocurrency development</a>
              </div>
              <h2 title="On-Chain Crypto Coin Development: Why On-Chain Crypto Is Vital for Modern Businesses in 2025?"> <a href="https://www.blockchainappfactory.com/blog/on-chain-crypto-coin-development/"> On-Chain Crypto Coin Development: Why On-Chain Crypto Is Vital for Modern Businesses in 2025? </a> </h2>
              <p>Blockchain technology is reshaping modern business foundations, offering solutions beyond innovation to address fundamental challenges like transparency, security, and efficiency. From revolutionizing financial transactions to streamlining supply chains and enhancing customer engagement, blockchain is steadily becoming a cornerstone of business…                                  </p><p><a href="https://www.blockchainappfactory.com/blog/on-chain-crypto-coin-development/"> Read More </a> </p>
            </div>
          </div>
        </div>
        <div className="client">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12 text-center">
              <img src="https://www.blockchainappfactory.com/blog/wp-content/uploads/2025/01/Agentic-AI-for-Building-Smarter-RAG-Applications.webp" />
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="post_category">
                <a className="btn">AI Development</a>
              </div>
              <h2 title="Agentic AI in Action: Building Intelligent, Autonomous RAG Applications"> <a href="https://www.blockchainappfactory.com/blog/agentic-ai-for-building-autonomous-rag-applications/"> Agentic AI in Action: Building Intelligent, Autonomous RAG Applications </a> </h2>
              <p>Artificial Intelligence (AI) has come a long way, hasn't it? From the early days of simple rule-based systems to today's sophisticated autonomous agents, the journey has been nothing short of remarkable. Let's take a closer look at this evolution and…                                  </p><p><a href="https://www.blockchainappfactory.com/blog/agentic-ai-for-building-autonomous-rag-applications/"> Read More </a> </p>
            </div>
          </div>
        </div>
        <div className="client">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12 text-center">
              <img src="https://www.blockchainappfactory.com/blog/wp-content/uploads/2025/01/AI-and-ML-for-Application-Modernization.webp" />
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="post_category">
                <a className="btn">AI Development</a>
              </div>
              <h2 title="AI and ML for Application Modernization: Transforming Legacy Systems for the Future"> <a href="https://www.blockchainappfactory.com/blog/ai-and-ml-for-application-modernization/"> AI and ML for Application Modernization: Transforming Legacy Systems for the Future </a> </h2>
              <p>Key Takeaways Modernizing legacy systems with AI and ML improves efficiency, reduces costs, and enhances scalability for businesses. Success relies on stakeholder alignment, skilled teams, and addressing ethical concerns like data privacy and algorithmic fairness. Businesses can leverage tailored AI…                                  </p><p><a href="https://www.blockchainappfactory.com/blog/ai-and-ml-for-application-modernization/"> Read More </a> </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */}



        </Layout>
    )
}

export default blog
